/**
    percentageGraph
    TableDescriptPopContents
*/

.percentage-graph {
    position: relative;
    width: 100%;
    height: 10px;
    /* border-radius: 20px; */
    background-color: var(--light-grey-1);
}

/* 이용시간 */
.percentage-graph .rate {
    position: absolute;
    top: 50%;
    left: 0;
    height: 10px;
    /* border-radius: 20px; */
    transform: translateY(-50%);
    background-color: var(--blue);
}

.percentage-graph .rate .label-info {
    position: absolute;
    top: -17px;
    font-size: 11px;

}

/* 초과시간 */
.percentage-graph .rate2 {
    position: absolute;
    top: 50%;
    left: 0;
    height: 10px;
    /* border-radius: 20px; */
    /* border-top-left-radius: -20px; */
    /* border-bottom-left-radius: -20px; */
    /* border-top-right-radius: 20px;
    border-bottom-right-radius: 20px; */

    transform: translateY(-50%);
    background-color: #94deef;


}

.percentage-graph .rate2 .label-info {
    position: absolute;
    top: -17px;
    font-size: 11px;

}

/* 예약시간 */
.percentage-graph .rate3 {
    position: absolute;
    top: 50%;
    left: 0;
    height: 10px;
    /* border-radius: 20px; */
    /* border-top-left-radius: -20px; */
    /* border-bottom-left-radius: -20px; */
    /* border-top-right-radius: 20px;
    border-bottom-right-radius: 20px; */

    transform: translateY(-50%);
    background-color: #139969;
}

.percentage-graph .rate3 .label-info {
    position: absolute;

    top: 15px;
    font-size: 11px;

}

/* 잔여시간 */
.percentage-graph .rate4 {
    position: absolute;
    top: 50%;
    left: 0;
    height: 10px;
    /* border-radius: 20px; */
    /* border-top-left-radius: -20px; */
    /* border-bottom-left-radius: -20px; */
    /* border-top-right-radius: 20px;
    border-bottom-right-radius: 20px; */

    transform: translateY(-50%);
    background-color: '#eeeeee';
}

.percentage-graph .rate4 .label-info {
    position: absolute;
    top: -17px;
    font-size: 11px;
    white-space: nowrap;
    width: 100%;
    text-align: right;

}


/* TableDescriptPopContents */

.table-pop-description {
    padding-bottom: 120px;
}

.table-pop-description ul {
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.table-pop-description ul li {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.table-pop-description ul li .table-description {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;

}

.table-pop-description ul li .label-small {
    width: 100%;
    flex-basis: 100%;
}

.table-pop-description ul li .order-num-label {
    margin: 0;
}

/******************  Tooltip *******************/
.tooltip-container {
    position: relative;
    display: inline-block;
    cursor: pointer;
}

.tooltip-text {
    visibility: hidden;
    width: 120px;
    background-color: #878787;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 10px 10px;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    /* Position the tooltip above the text */
    left: 50%;
    margin-left: -60px;
    /* Use half of the width to center the tooltip */
    opacity: 0;
    transition: opacity 0.3s;

}

.tooltip-container:hover .tooltip-text {
    word-break: keep-all;
    visibility: visible;
    opacity: 1;
}

.tooltip-text.small {
    font-size: 11px;
}