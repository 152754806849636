.manage-reservation {
    display: flex;
    min-height: 100%;
    justify-content: center;
    padding-top: calc(40px + 64px);
    background-color: var(--grey);
}

.manage-reservation.bg-white {
    background-color: var(--white);
}

@media (max-width: 1000px) {
    .manage-reservation {
        padding-top: calc(20px + 48px + 20px);
    }
}

.reservation-box {
    border-radius: var(--border-radius);
    margin-bottom: 500px;
    width: 100%;
    max-width: 1000px;
    padding: 0 30px;
}

@media (max-width: 1000px) {
    .reservation-box {
        padding: 0 25px;
        margin-bottom: 100px;
        max-width: 100%;
        width: 100%;
        min-width: 100%;
    }
}

.create-reservation {
    display: flex;
    gap: 20px;
    justify-content: space-between;
}

.mobile-button-set {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 20px 25px 50px;
    z-index: 7;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 6.25%);
}

.reservation-item-list {
    display: flex;
    flex-direction: column;
    gap: 28px;
}

.reservation-content {
    display: flex;
    flex-direction: column;
    gap: 40px;
    padding: 0 30px;
    width: calc(50% - 10px);
    flex-basis: calc(50% - 10px);
}

.reservation-history-summary {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    margin-bottom: 40px;
}

.reservation-history-section {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.subscription-history {
    margin-top: 40px;
}

.table-title-left {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.filter-list {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
}

.filter-item {
    background-color: var(--grey);
    padding: 6px 10px;
    border-radius: 6px;
}

.subscription-history table tr:nth-last-child(2) td,
.subscription-history table tr:nth-last-child(1) td {
    border-bottom: 0;
}

@media (max-width: 1000px) {
    .create-reservation {
        flex-direction: column;
        padding: 0;
        margin-bottom: 100px;
    }

    .reservation-history-summary {
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin-bottom: 24px;
    }

    .reservation-content {
        width: 100%;
        flex-basis: 100%;
        padding: 0;
    }

    .filter-item {
        padding: 6px 8px;
    }

    .subscription-history {
        margin-top: 24px;
    }
}

.manage-payment-button-wrap {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top: 40px;
    align-items: center;
    gap: 20px;
}

.manage-payment-button-wrap .description {
    line-height: 18px;
    max-width: 400px;
    word-break: keep-all;
}

.customer-subscription-info {
    display: flex;
}

.customer-subscription-info li {
    width: 25%;
    flex-basis: 25%;
}

.customer-subscription-info .title {
    display: block;
    margin-bottom: 20px;
}

.customer-subscription-info .description>span {
    display: block;
    margin-bottom: 8px;
}

.customer-subscription-info .description>span:last-child {
    margin-bottom: 0;
}

@media (max-width: 1000px) {
    .manage-payment-button-wrap {
        margin-top: 24px;
    }

    .customer-subscription-info {
        flex-direction: column;
    }

    .customer-subscription-info li {
        width: 100%;
        flex-basis: 100%;
        padding: 20px 0;
        border-bottom: 1px solid var(--line);
    }

    .customer-subscription-info li:first-child {
        padding-top: 0;
    }

    .customer-subscription-info li:last-child {
        padding-bottom: 0;
        border-bottom: 0;
    }

    .customer-subscription-info .title {
        font-size: var(--title-size-mid)
    }
}

.mypage {
    display: flex;
    gap: 20px;
    justify-content: space-between;
}

.mypage-section {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: calc(50% - 10px);
    flex-basis: calc(50% - 10px);
}

@media (max-width: 1000px) {
    .mypage {
        flex-direction: column;
    }

    .mypage-section {
        width: 100%;
        flex-basis: 100%;
    }
}

.user-info {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.user-info-wrap {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.mypage-section .item-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.mypage-section .item-list li {
    width: 100%;
}

.mypage-section .item-list.address li {
    align-items: flex-start;
}

.mypage-section .item-list.line li {
    padding-top: 12px;
    border-top: 1px solid var(--line);
}

.mypage-section .item-list.line li:first-child {
    border-top: none;
    padding-top: 0;
}

.mypage-section .item-list li {
    display: flex;
    gap: 10px;
    justify-content: space-between;
}

.mypage-section .white-box .button-set {
    gap: 12px;
}

.mypage-section .item-set {
    display: flex;
    justify-content: space-between;
    gap: 10px;
}

.mypage-section .item-set.w-240 {
    width: 240px;
    flex-basis: 240px;
}

.mypage-section .item-set.w-200 {
    width: 200px;
    flex-basis: 200px;
}

.mypage-section .item-set.w-180 {
    width: 180px;
    flex-basis: 180px;
}

.mypage-section .item-set.w-140 {
    width: 140px;
    flex-basis: 140px;
}

.mypage-section .item-list li .small {
    width: 90px;
    flex-basis: 90px;
}

.mypage-section .item-list li .xsmall {
    width: 60px;
    flex-basis: 60px;
}


.mypage-section .item-list.address .item-set {
    width: calc(100% - 80px);
}

.mypage-section .item-list li .address {
    width: calc(100% - 40px - 20px);
    line-height: 1.4;
}

.reservation-history-table table tr td:first-child {
    width: 100px;
}

.reservation-history-table table tr td:nth-child(2) {
    width: 200px;
}

@media (max-width: 1000px) {
    .mypage-section .item-list.member li {
        gap: 0;
    }

    .mypage-section .item-list li .mid-small {
        width: 90px;
        flex-basis: 90px;
    }

    .mypage-section .item-list li .small {
        width: 70px;
        flex-basis: 70px;
    }

    .reservation-history-table table tr td:first-child {
        width: 68px;
    }

    .reservation-history-table table tr td:nth-child(2) {
        width: auto;
    }



    .member-custom .item-set {
        gap: 0px;
    }

    .member-custom li {
        align-items: center;
    }

    .member-custom li .item-set {
        align-items: center;
    }

    .member-custom li .small {
        width: 70px!important;
        flex-basis: 70px!important;

        
    }

    .member-custom li .xsmall {
        width: 35px !important;
        flex-basis: 35px !important;
    }

    .member-custom li span {
        font-size: 12px !important;
    }

    .member-custom li span:last-child {
        width: 50px !important;
        flex-basis: 50px !important;
    }

    .member-custom .item-set.w-100 span {
        font-size: 12px !important;
        padding-right: 5px;
    }

    .member-custom .item-set.w-100 button {
        font-size: 12px !important;
    }

}


.partner-user-select {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px 10px;
}

.partner-user-select.selected {
    border: 2px solid #b5b5b5;
    border-radius: 15px;
}