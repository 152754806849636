@import 'variable.css';

button {
  padding: 22px;
  border-radius: var(--border-radius);
  width: 100%;
}

.white-box button {
  border-radius: 6px;
  line-height: 1.2;
}

.reservation-item .button-small {
  padding: 14px 16px;
}

.modal button {
  padding: 16px;
}

@media (max-width: 1000px) {
  button {
    padding: 16px;
    font-size: var(--txt-size-large);
  }

  button.button-small {
    padding: 12px 16px;
    font-size: var(--txt-size-regular);
  }

  .reservation-item .button-small {
    padding: 12px 16px;
  }

  .button-set button {
    font-size: var(--txt-size-regular);
  }
}

button.primary {
  background-color: var(--grey);
}

button.secondary {
  background-color: var(--blue);
  color: var(--white);
}

button.warning {
  background-color: var(--error);
  color: var(--white);
}


button.text {
  width: fit-content;
  padding: 0;
}

button.light-grey-2 {
  padding: 8px 10px;
  border-radius: 6px;
  background-color: var(--light-grey-2);
  color: var(--grey-1);
  line-height: 1;
}

button.grey-1 {
  padding: 8px 10px;
  border-radius: 6px;
  background-color: var(--grey-1);
  width: fit-content;
  color: var(--light-grey-2);
  line-height: 1;
}

button.grey-2 {
  padding: 8px 10px;
  border-radius: 6px;
  background-color: var(--grey-2);
  width: fit-content;
  color: var(--light-grey-2);
  line-height: 1;
}

button.text:hover {
  background-color: transparent;
  color: inherit;
}


button.tertiary {
  background-color: var(--grey-2);
  color: var(--light-grey-2);
  padding: 18px 22px;
  width: fit-content !important;
}

button.padding-10 {
  padding: 10px;
}

button.button-large {
  width: 280px;
}

button.padding-large {
  padding: 16px 43px;
}

button.h-48 {
  height: 48px;
}

@media (max-width: 1000px) {
  button.tertiary {
    padding: 13px 16px;
    font-size: var(--txt-size-regular);
  }

  button.padding-large {
    padding: 16px;
    width: 100% !important;
  }

  button.grey-1 {
    font-size: 11px;
  }
}

button.button-xsmall {
  padding: 6px 10px;
  background-color: var(--grey-1);
  font-size: 11px;
  border-radius: 6px;
}

button.icon {
  display: flex;
  align-items: center;
  gap: 8px;
  width: fit-content;
  padding: 14px 20px;
  color: var(--light-grey-2);
  background-color: var(--grey-2);
}

button.flex {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
}

button.menu,
button.transparent {
  padding: 0;
  background-color: transparent;
}

button.menu:hover,
button.transparent:hover {
  background-color: transparent;
}

@media (max-width: 1000px) {
  button.icon {
    padding: 10px 16px;
    font-size: var(--txt-size-regular);
  }

  button.menu,
  button.transparent {
    padding: 0;
    background-color: transparent !important;
  }
}

button.icon.secondary {
  padding: 0;
  background-color: transparent;
}

.datepicker-wrap button.icon {
  background-color: transparent;
}


button.btn-month {
  width: 36px;
  height: 36px;
  border-radius: 50%;
}

button.primary:hover {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),
    linear-gradient(0deg, #F6F6F6, #F6F6F6);

}

button.secondary:hover {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), linear-gradient(0deg, #1EAFD2, #1EAFD2);
}

button.tertiary:hover {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),
    linear-gradient(0deg, #7C7C7C, #7C7C7C);

}

.button-set {
  display: flex;
  justify-content: space-between;
  gap: 16px;
  align-items: flex-start;
}

@media (max-width: 1000px) {
  .button-set {
    gap: 12px;
  }

  .button-set .icon svg {
    width: 20px;
    height: 20px;
  }
}

.react-datepicker .button-set {
  margin-top: 0;
}

.button-set button {
  width: 100%;
}

.button-txt {
  cursor: pointer;
}

button.fit-content {
  width: fit-content;
}