@import 'variable.css';
@import url('https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/variable/pretendardvariable.css');

* {
  font-size: var(--txt-size-regular);
  font-family: 'Pretendard Variable', sans-serif;
  color: var(--txt-color-default);
  font-weight: var(--txt-weight-default);
  line-height: var(--txt-height-default);
}

h1,
h2,
h3,
h4,
h4,
h6 {
  margin: 0;
  padding: 0;
  line-height: 1;
}

a {
  text-decoration: none;
  color: var(--black);
}

.title-small {
  font-size: var(--title-size-small);
  color: var(--grey-2);
}

.title-mid-small {
  font-size: var(--title-size-mid-small);
  color: var(--grey-1);
}

.title-regular {
  font-size: var(--title-size-regular);
  color: var(--grey-1);
  font-weight: var(--txt-weight-bold);
}

.title-mid {
  font-size: var(--title-size-mid);
  color: var(--grey-1);
}

.title-large {
  font-size: var(--title-size-large);
  font-weight: var(--txt-weight-bold);
  color: var(--grey-1);
}

.txt-large {
  font-size: var(--txt-size-large);
}

.txt-regular {
  font-size: var(--txt-size-regular);
}

.txt-regular .date-flex {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.txt-small {
  font-size: var(--txt-size-small) !important;
}

.list-style li span {
  font-size: var(--list-size);
  line-height: var(--list-height);
}

@media (max-width: 1000px) {
  .title-small {
    font-size: var(--txt-size-regular);
  }

  .title-large {
    font-size: var(--title-size-regular);
  }
}

.grey {
  color: var(--grey);
}

.grey-1 {
  color: var(--grey-1);
}

.grey-2 {
  color: var(--grey-2);
}

.grey-3 {
  color: var(--grey-3);
}

.light-grey-3 {
  color: var(--light-grey-3)
}

.blue {
  color: var(--blue);
}

.white {
  color: var(--white);
}

.light-blue {
  color: var(--light-blue);
}

.underline {
  position: relative;
  color: var(--grey-2);
}

.underline::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 1px;
  bottom: -2px;
  left: 0;
  background-color: var(--light-grey-3);
}

.align-right {
  text-align: right;
  justify-content: right;
}

.error {
  color: var(--error);
  font-size: var(--txt-size-error);
}

.block {
  display: block;
}

.page-title-wrap {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

@media (max-width: 1000px) {
  .page-title-wrap+.see-more {
    margin-top: 16px;
  }
}

.align-center {
  text-align: center;
}

.align-center * {
  text-align: center;
}

.bold {
  font-weight: 600;
}

.disabled {
  color: var(--disabled);
}

.lh12 {
  line-height: 1.2;
}