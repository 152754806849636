/**
   list-box
   list-with-check-icon
   white-box
**/

/* list-box */
.list-box {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 20px 0;
    border-bottom: 1px solid var(--line);
}

.list-box .list-box-list {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.list-box .list-box-list .list-item {
    display: flex;
    justify-content: space-between;
}

/* new-list-box Begin */
.new-list-box {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 20px 0;
    /* border-bottom: 1px solid var(--line); */
}

.new-list-box .unit {
    text-align: right;
}

.new-list-box .sub {
    display: flex;
    flex-direction: row;
    gap: 12px;
    padding: 10px 0;
    justify-content: space-between;
}

.new-list-box .sub.title {
    background-color: #d2eff6;
    border-bottom: 1px solid var(--blue);
}

.new-list-box .sub.title div {
    color: var(--blue);
}

.new-list-box .sub-list {
    display: flex;
    flex-direction: row;
    gap: 12px;
    padding: 5px 0;
    justify-content: space-between;
}

.new-list-box .sub div:first-child {
    width: 200px;
    word-break: keep-all;
    color: var(--blue);
    padding-left: 10px;

}

.new-list-box .sub div:nth-child(2) {
    width: 100px;
}

.new-list-box .sub div:nth-child(3) {
    text-align: right;
    width: 100px;
    color: var(--blue);
    padding-right: 10px;
}

.new-list-box .sub-list div:first-child {
    width: 200px;
    word-break: keep-all;
    padding-left: 10px;
}

.new-list-box .sub-list div:nth-child(2) {
    width: 100px;
    text-align: right;
}

.new-list-box .sub-list div:nth-child(3) {
    text-align: right;
    width: 100px;
    padding-right: 10px;
}

.dead-line {
    text-decoration: line-through;
}

.new-list-box .sub.result div {
    color: #cb2b29;
}

.new-list-box .option.title {
    padding: 8px 0px;
    color: var(--blue)
}

.new-list-box .option.wrap {
    padding: 10px 10px;
    /* background-color: #fef7eb; */
}

.new-list-box .option.list {
    padding: 5px 0px;
    display: flex;
    flex-direction: row;
}

.new-list-box .option.list div:first-child {
    width: 200px;
}

.new-list-box .option.list div:nth-child(2) {
    width: 800px;
    word-break: break-all;
}

.new-list-box .option.list.estimateview div:first-child {
    width: 350px;
}

.new-list-box .option.list.estimateview div:nth-child(2) {
    width: 800px;
}

@media (max-width: 1000px) {
    .new-list-box .option.list div:first-child {
        width: 40%;
    }

    .new-list-box .option.list div:nth-child(2) {
        width: 60%;
    }

    .new-list-box .option.list.estimateview div:first-child {
        width: 40%;
    }

    .new-list-box .option.list.estimateview div:nth-child(2) {
        width: 60%;
    }

}


.new-list-box .estimate {
    display: flex;
    flex-direction: row;
    gap: 12px;
    padding: 5px 0;
    justify-content: space-between;
}

.new-list-box .estimate .estimate-sub {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 5px 0;

}

.new-list-box .estimate .estimate-sub .title {
    color: var(--blue);

}

.new-list-box .estimate .estimate-sub .content div {
    padding: 3px 0px
}

.new-list-box .estimate .estimate-sub-date {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 5px 0;

}

.new-list-box .estimate .estimate-sub-date .flex {
    display: flex;
    flex-direction: row;
    gap: 15px;
    padding: 3px 0;
    justify-content: space-between;
}

.new-list-box .estimate .estimate-sub-date .flex .title {
    color: var(--blue);
}

.new-list-box .estimate .estimate-sub-date .flex .date {
    /* background-color: #fef7eb; */
}





@media (max-width: 1000px) {
    .new-list-box .sub div:first-child {
        width: 40%;
        word-break: keep-all;
    }

    .new-list-box .sub div:nth-child(2) {
        width: 25%;
    }

    .new-list-box .sub div:nth-child(3) {
        text-align: right;
        width: 35%;
    }

    .new-list-box .sub-list div:first-child {
        width: 40%;
        word-break: keep-all;
    }

    .new-list-box .sub-list div:nth-child(2) {
        width: 25%;
    }

    .new-list-box .sub-list div:nth-child(3) {
        text-align: right;
        width: 35%;
    }
}

/* new-list-box End */



/* list-with-check-icon */
.list-with-check {
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-width: 512px;
}

.list-with-check li {
    display: flex;
    justify-content: flex-start;
    gap: 8px;

}

.list-with-check li .text {
    line-height: var(--title-size-large);

}

/* white-box */
.white-box {
    padding: 20px;
    background-color: var(--white);
    border-radius: 10px;
    box-shadow: 0px 2px 4px 0px #0000001A;
}

.reservation-history .white-box {
    padding: 18px;
}

.white-box .title-set {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}


.white-box .title {
    display: block;
}

.reservation-item {
    display: flex;
    flex-direction: column;
    gap: 12px;
}


@media (min-width: 1000px) {
    .mypage .white-box {
        padding: 28px;
    }

    .reservation-item {
        gap: 10px;
    }
}