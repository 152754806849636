/**
    MobileDescriptionPop
*/

/** MobileDescriptionPop **/
.mobile-description-pop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--white);
    z-index: 11;
}

.mobile-description-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 48px;
    padding: 13px 20px;
    display: flex;
    justify-content: flex-end;
    background-color: var(--white);
    z-index: 11;
}

.mobile-description-pop-content {
    padding: 40px 25px 100px;
    height: 100%;
    overflow: scroll;
}

.mobile-description-pop-content .title {
    padding: 20px 0;
    border-bottom: 1px solid var(--line);
}

.mobile-description-pop-content .description-wrap {
    margin: 20px 0 40px;
}

.mobile-description-pop-button {
    position: fixed;
    bottom: -1px;
    left: 0;
    padding: 20px 25px;
    width: 100%;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 6.25%);
}

/* PaymentHistory */
.completed-payment-item {
    border-bottom: 1px solid var(--line);
    padding-bottom: 12px;
    margin-bottom: 12px;
}

.payment-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.payment-wrap .amount-set {
    display: flex;
    align-items: center;
    gap: 8px;
}

.pending-payment-item {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.pending-details {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.pending-details li {
    display: flex;
    justify-content: space-between;
}