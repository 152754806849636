@import 'variable.css';
@import 'common.css';
@import 'heading.css';
@import 'button.css';
@import 'atoms.css';
@import 'organism.css';
@import 'molecules.css';
@import 'datepicker.css';

html {
  height: 100%;
  min-width: 100%;
}

body {
  height: 100%;
}

#root {
  height: 100%;
}

.App {
  display: grid;
  grid-template-rows: 1fr;
  min-height: 100%;
  margin: 0;
  grid-template-areas: " main";
}

main {
  grid-area: main;
}

.home {
  display: flex;
  flex-direction: column;
  gap: 10px;
  min-height: 100%;
  justify-content: center;
  align-items: center;
}

.home a {
  display: block;
  padding: 20px;
  background-color: var(--blue);
  border-radius: var(--border-radius);
  color: var(--white)
}

.dim {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 20;
  opacity: 0.5;
  background-color: var(--black);
}

.modal {
  position: fixed;
  min-width: 325px;
  top: 50%;
  left: 50%;
  padding: 24px;
  z-index: 30;
  transform: translate(-50%, -50%);
  background-color: var(--white);
  border-radius: 12px;
}

.modal .title-set {
  display: flex;
  text-align: center;
  flex-direction: column;
  gap: 14px;
  margin-bottom: 28px;
}

.modal .main-set {
  display: flex;
  text-align: center;
  flex-direction: column;
  gap: 14px;
  margin-bottom: 28px;
}

.modal2 {
  position: fixed;
  min-width: 1000px;
  min-height: 600px;
  top: 50%;
  left: 50%;
  padding: 24px;
  z-index: 30;
  transform: translate(-50%, -50%);
  background-color: var(--white);
  border-radius: 12px;
}

.modal2 .title-set {
  display: flex;
  text-align: center;
  flex-direction: column;
  gap: 14px;
  margin-bottom: 28px;
}

.modal2 .main-set {
  display: flex;
  text-align: center;
  flex-direction: column;
  gap: 14px;
  margin-bottom: 28px;
}

.modal3 {
  position: fixed;
  min-width: 1000px;
  min-height: 600px;
  top: 50%;
  left: 50%;
  padding: 24px;
  z-index: 30;
  transform: translate(-50%, -50%);
  background-color: var(--white);
  border-radius: 12px;
}

.modal3 .title-set {
  display: flex;
  text-align: center;
  flex-direction: column;
  gap: 14px;
  margin-bottom: 28px;
}

.modal3 .main-set {
  display: flex;
  text-align: center;
  flex-direction: column;
  gap: 14px;
  margin-bottom: 28px;
}

.schedule {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.input-list:not(:first-of-type) {
  border-top: 1px solid var(--line);
  padding-top: 32px;
}

.input-list .input-list-title {
  display: block;
  margin: 0 0 8px 10px;
}

.input-list .input-items {
  display: flex;
  gap: 16px;
}


/*custom select*/
.select-set {
  position: relative;
}

.select-item-wrapper {
  position: absolute;
  border: none;
  border-radius: var(--border-radius);
  overflow: auto;
  box-shadow: 0px 4px 16px 0px #00000029;
  z-index: 3;
  top: 100%;
  left: 0;
  width: 100%;
  margin-top: 10px;
  max-height: calc(44px * 5);
  background-color: var(--white);
}

.select-item-wrapper * {
  cursor: url('../components/atoms/Icon/svg/cursor_hand.svg'), auto;
}

.select-item-wrapper .select-item {
  height: 44px;
  padding: 13px 16px;
}

.select-item:hover {
  background-color: var(--bg);
}

@media (max-width: 1000px) {
  .footer-contents {
    width: calc(100% + 50px);
    padding: 20px 25px 250px;
    margin-left: -25px;
    margin-top: 50px;
    margin-bottom: -300px;
  }

  .footer-contents ul li {
    text-align: left;
  }

  .footer-contents2 {
    width: calc(100% + 50px);
    padding: 20px 25px;
    margin-left: -25px;

  }

  .footer-contents2 ul li {
    text-align: left;
  }

  svg.arrow_right,
  svg.arrow_left {
    width: 20px;
    height: 20px;
  }
}

/* 로그인 */
.login {
  display: flex;
  justify-content: center;
}

.login-form {
  display: flex;
  flex-direction: column;
  gap: 28px;
  width: 340px;
}

.input-set {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.link-set {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.social-button-set {
  display: flex;
  flex-direction: column;
  gap: 14px;
}

@media (max-width: 1000px) {
  .login-form {
    width: 100%;
    max-width: 340px;
  }
}

.ReactModal__Content {
  border: 1px solid #d0d0d0;
  outline: none;

}