@charset "UTF-8";

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow,
.react-datepicker__navigation-icon::before {
    border-color: #ccc;
    border-style: solid;
    border-width: 3px 3px 0 0;
    content: "";
    display: block;
    height: 9px;
    position: absolute;
    top: 6px;
    width: 9px;
}

.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle,
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle {
    margin-left: -4px;
    position: absolute;
    width: 0;
}

.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::after,
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
    box-sizing: content-box;
    position: absolute;
    border: 8px solid transparent;
    height: 0;
    width: 1px;
    content: "";
    z-index: -1;
    border-width: 8px;
    left: -8px;
}

.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before {
    border-bottom-color: #aeaeae;
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle {
    top: 0;
    margin-top: -8px;
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
    border-top: none;
    border-bottom-color: #f0f0f0;
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
    top: 0;
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before {
    top: -1px;
    border-bottom-color: #aeaeae;
}

.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle {
    bottom: 0;
    margin-bottom: -8px;
}

.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::after {
    border-bottom: none;
    border-top-color: #fff;
}

.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::after {
    bottom: 0;
}

.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before {
    bottom: -1px;
    border-top-color: #aeaeae;
}

.react-datepicker-wrapper {
    width: 100%;
    display: inline-block;
    padding: 0;
    border: 0;
}

.react-datepicker {
    font-family: "Helvetica Neue", helvetica, arial, sans-serif;
    font-size: 0.8rem;
    background-color: #fff;
    color: #000;
    display: inline-block;
    position: relative;
}

.react-datepicker--time-only .react-datepicker__triangle {
    left: 35px;
}

.react-datepicker--time-only .react-datepicker__time-container {
    border-left: 0;
}

.react-datepicker--time-only .react-datepicker__time,
.react-datepicker--time-only .react-datepicker__time-box {
    border-bottom-left-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;
}

.react-datepicker__triangle {
    position: absolute;
    left: 50px;
}

.react-datepicker-popper {
    z-index: 1;
}

.react-datepicker-popper[data-placement=bottom-end] .react-datepicker__triangle,
.react-datepicker-popper[data-placement=top-end] .react-datepicker__triangle {
    left: auto;
    right: 50px;
}

.react-datepicker-popper[data-placement^=top] {
    padding-bottom: 10px;
}

.react-datepicker-popper[data-placement^=right] {
    padding-left: 8px;
}

.react-datepicker-popper[data-placement^=right] .react-datepicker__triangle {
    left: auto;
    right: 42px;
}

.react-datepicker-popper[data-placement^=left] {
    padding-right: 8px;
}

.react-datepicker-popper[data-placement^=left] .react-datepicker__triangle {
    left: 42px;
    right: auto;
}

.react-datepicker__header {
    text-align: center;
    background-color: #f0f0f0;
    border-bottom: 1px solid #aeaeae;
    border-top-left-radius: 0.3rem;
    padding: 8px 0;
    position: relative;
}

.react-datepicker__header--time {
    padding-bottom: 8px;
    padding-left: 5px;
    padding-right: 5px;
}

.react-datepicker__year-dropdown-container--select,
.react-datepicker__month-dropdown-container--select,
.react-datepicker__month-year-dropdown-container--select,
.react-datepicker__year-dropdown-container--scroll,
.react-datepicker__month-dropdown-container--scroll,
.react-datepicker__month-year-dropdown-container--scroll {
    display: inline-block;
    margin: 0 15px;
}

.react-datepicker-time__header {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.react-datepicker__navigation {
    align-items: center;
    background: none;
    display: flex;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    position: absolute;
    top: 2px;
    padding: 0;
    border: none;
    z-index: 1;
    height: 32px;
    width: 32px;
    text-indent: -999em;
    overflow: hidden;
}

.react-datepicker__navigation--previous {
    left: 2px;
}

.react-datepicker__navigation--next {
    right: 2px;
}

.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
    right: 85px;
}

.react-datepicker__navigation--years {
    position: relative;
    top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.react-datepicker__navigation--years-previous {
    top: 4px;
}

.react-datepicker__navigation--years-upcoming {
    top: -4px;
}

.react-datepicker__navigation:hover *::before {
    border-color: #a6a6a6;
}

.react-datepicker__navigation-icon {
    position: relative;
    top: -1px;
    font-size: 20px;
    width: 0;
}

.react-datepicker__navigation-icon--next {
    left: -2px;
}

.react-datepicker__navigation-icon--next::before {
    transform: rotate(45deg);
    left: -7px;
}

.react-datepicker__navigation-icon--previous {
    right: -2px;
}

.react-datepicker__navigation-icon--previous::before {
    transform: rotate(225deg);
    right: -7px;
}

.react-datepicker__month-container {
    float: left;
}

.react-datepicker__year {
    margin: 0.4rem;
    text-align: center;
}

.react-datepicker__year-wrapper {
    display: flex;
    flex-wrap: wrap;
    max-width: 180px;
}

.react-datepicker__year .react-datepicker__year-text {
    display: inline-block;
    width: 4rem;
    margin: 2px;
}

.react-datepicker__month {
    margin: 0.4rem;
    text-align: center;
}

.react-datepicker__month .react-datepicker__month-text,
.react-datepicker__month .react-datepicker__quarter-text {
    display: inline-block;
    width: 4rem;
    margin: 2px;
}

.react-datepicker__input-time-container {
    clear: both;
    width: 100%;
    float: left;
    margin: 5px 0 10px 15px;
    text-align: left;
}

.react-datepicker__input-time-container .react-datepicker-time__caption {
    display: inline-block;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container {
    display: inline-block;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input {
    display: inline-block;
    margin-left: 10px;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input {
    width: auto;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type=time]::-webkit-inner-spin-button,
.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type=time]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type=time] {
    -moz-appearance: textfield;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__delimiter {
    margin-left: 5px;
    display: inline-block;
}

.react-datepicker__time-container {
    float: right;
    border-left: 1px solid #aeaeae;
    width: 85px;
}

.react-datepicker__time-container--with-today-button {
    display: inline;
    border: 1px solid #aeaeae;
    position: absolute;
    right: -87px;
    top: 0;
}

.react-datepicker__time-container .react-datepicker__time {
    position: relative;
    background: white;
    border-bottom-right-radius: 0.3rem;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
    width: 85px;
    overflow-x: hidden;
    margin: 0 auto;
    text-align: center;
    border-bottom-right-radius: 0.3rem;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
    list-style: none;
    margin: 0;
    height: calc(195px + (1.7rem / 2));
    overflow-y: scroll;
    padding-right: 0;
    padding-left: 0;
    width: 100%;
    box-sizing: content-box;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
    height: 30px;
    padding: 5px 10px;
    white-space: nowrap;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover {
    cursor: pointer;
    background-color: #f0f0f0;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled:hover {
    cursor: default;
    background-color: transparent;
}

.react-datepicker__week-number {
    display: inline-block;
}

.react-datepicker__week-number.react-datepicker__week-number--clickable {
    cursor: pointer;
}

.react-datepicker__day-names,
.react-datepicker__week {
    white-space: nowrap;
}

.react-datepicker__day-names {
    margin-bottom: -8px;
}

.react-datepicker__day,
.react-datepicker__month-text,
.react-datepicker__quarter-text,
.react-datepicker__year-text {
    cursor: pointer;
}


.react-datepicker__input-container {
    position: relative;
    display: inline-block;
    width: 100%;
}

.react-datepicker__input-container .react-datepicker__calendar-icon {
    position: absolute;
    padding: 0.5rem;
}

.react-datepicker__view-calendar-icon input {
    padding: 6px 10px 5px 25px;
}

.react-datepicker__year-read-view,
.react-datepicker__month-read-view,
.react-datepicker__month-year-read-view {
    border: 1px solid transparent;
    border-radius: 0.3rem;
    position: relative;
}

.react-datepicker__year-read-view:hover,
.react-datepicker__month-read-view:hover,
.react-datepicker__month-year-read-view:hover {
    cursor: pointer;
}

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
    transform: rotate(135deg);
    right: -16px;
    top: 0;
}

.react-datepicker__year-dropdown,
.react-datepicker__month-dropdown,
.react-datepicker__month-year-dropdown {
    position: absolute;
    width: 50%;
    left: 25%;
    top: 30px;
    z-index: 1;
    text-align: center;
}

.react-datepicker__year-dropdown:hover,
.react-datepicker__month-dropdown:hover,
.react-datepicker__month-year-dropdown:hover {
    cursor: pointer;
}

.react-datepicker__year-dropdown--scrollable,
.react-datepicker__month-dropdown--scrollable,
.react-datepicker__month-year-dropdown--scrollable {
    height: 150px;
    overflow-y: scroll;
}

.react-datepicker__year-option,
.react-datepicker__month-option,
.react-datepicker__month-year-option {
    line-height: 20px;
    width: 100%;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.react-datepicker__year-option:first-of-type,
.react-datepicker__month-option:first-of-type,
.react-datepicker__month-year-option:first-of-type {
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
}

.react-datepicker__year-option:last-of-type,
.react-datepicker__month-option:last-of-type,
.react-datepicker__month-year-option:last-of-type {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.react-datepicker__year-option--selected,
.react-datepicker__month-option--selected,
.react-datepicker__month-year-option--selected {
    position: absolute;
    left: 15px;
}

.react-datepicker__close-icon {
    cursor: pointer;
    background-color: transparent;
    border: 0;
    outline: 0;
    padding: 0 6px 0 0;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    display: table-cell;
    vertical-align: middle;
}

.react-datepicker__close-icon::after {
    cursor: pointer;
    background-color: #216ba5;
    color: #fff;
    border-radius: 50%;
    height: 16px;
    width: 16px;
    padding: 2px;
    font-size: 12px;
    line-height: 1;
    text-align: center;
    display: table-cell;
    vertical-align: middle;
    content: "×";
}

.react-datepicker__today-button {
    background: #f0f0f0;
    border-top: 1px solid #aeaeae;
    cursor: pointer;
    text-align: center;
    padding: 5px 0;
    clear: left;
}

.react-datepicker__portal {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    left: 0;
    top: 0;
    justify-content: center;
    align-items: center;
    display: flex;
    z-index: 2147483647;
}

.react-datepicker__portal .react-datepicker__day-name,
.react-datepicker__portal .react-datepicker__day,
.react-datepicker__portal .react-datepicker__time-name {
    width: 3rem;
    line-height: 3rem;
}

@media (max-width: 400px),
(max-height: 550px) {

    .react-datepicker__portal .react-datepicker__day-name,
    .react-datepicker__portal .react-datepicker__day,
    .react-datepicker__portal .react-datepicker__time-name {
        width: 2rem;
        line-height: 2rem;
    }
}

.react-datepicker__portal .react-datepicker__current-month,
.react-datepicker__portal .react-datepicker-time__header {
    font-size: 1.44rem;
}

.react-datepicker__children-container {
    width: 13.8rem;
    margin: 0.4rem;
    padding-right: 0.2rem;
    padding-left: 0.2rem;
    height: auto;
}

.react-datepicker__aria-live {
    position: absolute;
    clip-path: circle(0);
    border: 0;
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    width: 1px;
    white-space: nowrap;
}

.react-datepicker__calendar-icon {
    width: 1em;
    height: 1em;
    vertical-align: -0.125em;
}


/*react date picker*/
.datepicker-wrap {
    position: relative;
}

.react-datepicker__aria-live {
    display: none;
}

.datepicker-wrap .schedule {
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
}

.react-datepicker__tab-loop__end {
    display: none;
}

.react-datepicker {
    position: absolute;
    border: none;
    border-radius: var(--border-radius);
    overflow: auto;
    box-shadow: 0px 4px 16px 0px #00000029;
    z-index: 3;
}

.react-datepicker__month,
.react-datepicker__header {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
    border: none;
    background: transparent;
}

.react-datepicker__month {
    padding: 0 17px 12px 17px;
}

.datepicker__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 23px 15px 16px 15px;
}

.datepicker__header .year-month {
    font-size: 16px;
    line-height: 1;
}

.datepicker__header button {
    width: 22px;
    height: 22px;
    padding: 0;
}

.react-datepicker__week,
.react-datepicker__day-names {
    display: flex;
    justify-content: space-between;
}

.react-datepicker__day-names {
    padding: 0 17px;
}

.react-datepicker__day--today,
.react-datepicker__month-text--today,
.react-datepicker__quarter-text--today,
.react-datepicker__year-text--today {
    font-weight: var(--txt-weight-default);
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
    width: 43px;
    height: 43px;
    font-size: 16px;
    text-align: center;
    line-height: 43px;
    margin: 4px;
    border-radius: 50%;
}

.react-datepicker__day-name {
    color: var(--grey-3);
    font-size: var(--txt-size-regular);
}

.react-datepicker__day--outside-month {
    color: var(--line);
    cursor: not-allowed;
}

.react-datepicker__day--outside-month:hover {
    background-color: transparent;
}

.react-datepicker__day:hover {
    /* background-color: var(--blue-10);
    color: var(--blue); */
}

.react-datepicker__day--in-range,
.react-datepicker__day--keyboard-selected,
.react-datepicker__day--selected {
    background-color: var(--blue);
    color: var(--white);
}

.react-datepicker__day--in-range:hover,
.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__day--selected:hover {
    /* background-color: var(--blue-hover);
    color: var(--white); */
}

.react-datepicker-popper {
    z-index: 11;
}

@media (max-width: 1000px) {
    .react-datepicker-popper[data-placement^=top] {
        padding: 0;
    }

    .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-name {
        width: 100%;
        height: auto;
        /* aspect-ratio: 1/1; */
        padding-top: 3.5%;
        padding-bottom: 3.5%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        line-height: 1;
        text-align: center;
        margin: 4px;
        border-radius: 50%;
    }

    .react-datepicker-popper {
        /* overflow: hidden; */
        /* ios에서 이 속성을 없애니 잘 나옴...... overflow: hidden; */

        position: fixed !important;
        width: calc(100% - 50px);
        /* aspect-ratio: 1/1; */
        /* padding-top: 3%;
        padding-bottom: 3%; */
        top: 50% !important;
        left: 50% !important;
        transform: translate(-50%, -50%) !important;
        background-color: var(--white);
        border-radius: var(--border-radius);
    }

    .datepicker__header {
        padding: 15px 24px 10px;
    }

    .react-datepicker__month {
        padding: 0 13px 12px 13px;
    }

    .react-datepicker__day-names {
        padding: 0 13px;
    }

    .react-datepicker-popper>div {
        position: relative;
        width: 100%;
        height: 100%;
    }

    .react-datepicker {
        width: calc(100% - 10px);
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        box-shadow: none;
    }

    .react-datepicker__month-container {
        width: 100%;
    }

    .react-datepicker__tab-loop::before {
        content: '';
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0.5;
        background-color: var(--black);
        z-index: 11;
    }

}


button.react-calendar__tile[disabled] abbr {
    color: var(--light-grey-3)
}


.react-datepicker__day--disabled {
    color: var(--light-grey-1)
}


/** calendar **/

.react-calendar {
    border-radius: 10px;
    padding: 0 15px 15px 15px;
    background-color: var(--white);
    box-shadow: 0px 2px 4px 0px #0000001A;
}

.react-calendar button {
    border-radius: 50%;
    padding: 18px;
}

.react-calendar__tile {
    display: flex;
    align-items: center;
    justify-content: center;
}

.react-calendar__tile.highlight abbr {
    color: var(--blue);
    background-color: var(--light-grey-2);
}

.react-calendar__tile.react-calendar__tile--active {
    color: var(--blue);
}

.react-calendar__tile.react-calendar__tile--active:hover {
    color: var(--grey-2)
}

.react-calendar__tile.react-calendar__tile--active:hover abbr {
    background: var(--blue-hover-bg);
    color: var(--light-grey-2)
}


.react-calendar__tile--hasActive:hover {
    background-color: var(--blue);
    color: var(--light-grey-2)
}

.react-calendar__tile--hasActive.react-calendar__month-view__days__day abbr {
    background-color: var(--blue);
    color: var(--light-grey-2)
}

.react-calendar__tile--hasActive.react-calendar__month-view__days__day:hover abbr:hover {
    background-color: var(--blue);
    color: var(--light-grey-2)
}


.react-calendar__tile.react-calendar__month-view__days__day {
    display: flex;
    align-items: center;
    justify-content: center;
    aspect-ratio: 1/1;
    padding: 4px;
}

.react-calendar__month-view__days__day.react-calendar__tile--active abbr {
    color: var(--light-grey-2);
    background-color: var(--blue);
}

.react-calendar__tile:hover {
    background-color: transparent;
}

.react-calendar__tile abbr {
    aspect-ratio: 1/1;
    font-size: var(--txt-size-large);
    border-radius: 50%;
    padding: 0;
    width: 36px;
    height: 36px;
    display: flex;
    margin: 0;
    align-items: center;
    justify-content: center;
}

.react-calendar__tile--active:hover {
    color: var(--grey)
}

.react-calendar__tile--active abbr {
    color: var(--white);
    background-color: var(--blue);
}

/* calendar hover */
/* day */
.react-calendar__month-view__days__day.react-calendar__tile:hover abbr {
    background-color: var(--light-grey-2);
    color: #000;
}

.react-calendar__decade-view__years__year:hover abbr,
.react-calendar__year-view__months__month:hover abbr {
    color: var(--grey-2);
}

.react-calendar__tile--now:enabled:hover abbr,
.react-calendar__tile--now:enabled:hover abbr {
    color: var(--blue);
    background-color: var(--light-grey-2);
}

.react-calendar__navigation {
    display: flex;
    padding: 20px 0;
}

.react-calendar__navigation button {
    padding: 0;
    width: auto;

}

.react-calendar__month-view__weekdays {
    padding: 17px 0;
}

/** 요일 텍스트 커스텀 */
.react-calendar__month-view__weekdays__weekday {
    text-align: center;
}

.react-calendar__month-view__weekdays__weekday abbr {
    color: var(--grey-3)
}

abbr {
    text-decoration: none;
}

/* 현 월이 아닌 days */
.react-calendar__month-view__days__day--neighboringMonth abbr {
    color: var(--light-grey-1);
}


.react-calendar__navigation__label>span {
    font-size: var(--txt-size-large);
    color: var(--grey-1);
}

.react-calendar__navigation__label:hover,
.react-calendar__navigation__arrow:hover {
    background-color: transparent;
}


.react-calendar__tile.react-calendar__month-view__days__day.saturday:not([disabled]):not(.react-calendar__month-view__days__day--neighboringMonth) abbr {
    color: blue;
}


.react-calendar__tile.react-calendar__month-view__days__day.sunday:not([disabled]):not(.react-calendar__month-view__days__day--neighboringMonth) abbr {
    color: red;
}

.react-calendar__tile.reserved {
    position: relative;
    color: green !important;
}

.react-calendar__tile.reserved abbr {
    color: rgb(149, 216, 232) !important;
}

.react-calendar__tile.reserved abbr:after {
    font-size: 9px;
    content: "예약";
    position: absolute;
    bottom: 11px;
    left: 50%;
    transform: translateX(-50%);
}

@media (max-width: 1000px) {
    .react-calendar__tile.reserved abbr:after {
        font-size: 9px;
        content: "예약";
        position: absolute;
        bottom: 5px;
        left: 50%;
        transform: translateX(-50%);
    }

}