.header {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 8;
    border-bottom: 1px solid var(--light-grey-2);
    padding: 20px 32px 20px 32px;
    background-color: #FFFFFF;
}

.bg-white .header {
    border-bottom: none;
}

@media (max-width: 1000px) {
    .header {
        padding: 13px 23px;
    }
}

.header-menu-list {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
}

@media (max-width: 1000px) {
    .header-menu-list {
        justify-content: space-between;
    }
}

.mobile-nav {
    position: fixed;
    width: 100%;
    top: 0;
    right: 0;
    left: 0;
    z-index: 30;
    background-color: var(--white);
}

.mobile-nav .nav-header {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 13px 17px;
}
.mobile-nav .mobile-nav-list {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 32px;
    padding: 0 17px 32px;
}

.mobile-nav .mobile-nav-list li {
    display: flex;
    justify-content: center;
}

.header-logo {
    position: absolute;
    top: 50%;
    left: 32px;
    transform: translateY(-50%);
}

.header-close {
    position: absolute;
    top: 50%;
    right: 32px;
    transform: translateY(-50%);
}

.reservation-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.reservation-header .w-120 {
    width: 120px;
    flex-basis: 120px;
}

.menu-list {
    display: flex;
    gap: 32px;
}

.menu-list li span.active {
    color: var(--blue);
}

    /** step **/
.step-list{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5px;
}

.step-list .step{
    display: flex;
    align-items: center;
    gap: 5px;
}

@media (max-width: 1000px) {
    .step-list {
        gap: 0;
        margin-bottom: 20px;
    }

    .step-list .step {
        height: 4px;
        width: 100%;
        background-color: var(--line);
    }

    .step-list .step.active{
        background-color: var(--blue);
    }
}