/* Colors */
:root {
  --white: #FFFFFF;
  --black: #323232;
  --blue: #1EAFD2;
  --blue-10: #E2EBFF;
  --blue-hover: #2D61D0;
  --light-blue: #3183FF;
  --grey: #F6F6F6;
  --grey-1: #4F4F4F;
  --grey-2: #7C7C7C;
  --grey-3: #999999;
  --light-grey-1: #EEEEEE;
  --light-grey-2: #F6F6F6;
  --light-grey-3: #C8C8C8;
  --green: #18BD82;
  --pink: #FFEEEE;
  --red: #C53434;
  --placeholder: #D2D2D2;
  --disabled: #D9D9D9;
  --bg: #F9F9F9;
  --error: #EF3838;
  --table-border: #C0C0C0;
  --blue-hover-bg: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), linear-gradient(0deg, #1EAFD2, #1EAFD2);
}

/* Text */
:root {
  --txt-color-default: var(--grey-1);
  --title-size-large: 30px;
  --title-size-regular: 24px;
  --title-size-mid: 20px;
  --title-size-mid-small: 18px;
  --title-size-small: 16px;
  --txt-size-large: 16px;
  --txt-size-regular: 14px;
  --txt-size-small:12px ;
  --txt-size-error: 12px;
  --txt-height-default: 1;
  --txt-weight-default: 500;
  --txt-weight-bold: 600;
  --list-height: 24px;
  --list-size: 15px;
}

/* Line */
:root {
  --line: #D9D9D9;
}

/* Border */
:root {
  --border-radius: 6px;
}

@media (max-width: 1000px) {
  :root {
    --list-size: 14px;
  }
}

/* breakpoint */
:root {
  --breakpoint: 1000px;
}
