@import 'variable.css';

/* ios 애플계열 input 스타일 무시 */
input,
select,
textarea {
  user-select: text;
  border-radius: 0;
  -webkit-border-radius: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}


* {
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  /* Disable selection/copy in UIWebView */
}

button,
a {
  line-height: var(--txt-height-default);
}

button {
  border: none;
  background-color: transparent;
  cursor: pointer;
}

a {
  display: inline-block;
  text-decoration: none;
  color: var(--txt-color-default);
}

a.logo {
  display: block;
  width: 100px;
}

i {
  text-decoration: none;
  font-style: normal;
  font-size: var(--list-size);
}

ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}

ul.list-style {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

ul.list-style.gap-0 {
  gap: 0px;
}

ul.list-style li {
  position: relative;
  padding-left: 20px;
}

ul.list-style li:before {
  content: '·';
  position: absolute;
  line-height: var(--list-height);
  width: var(--list-height);
  height: var(--list-height);
  color: var(--grey-2);
  top: 0px;
  left: 5px;
}


fieldset {
  margin: 0;
  padding: 0;
  border: 0;
}

legend {
  padding: 0;
  margin: 0;
}

.hidden {
  display: none;
}

.cursor-pointer {
  cursor: pointer;
}


select,
textarea,
input[type='text'],
input[type='number'],
input[type='tel'],
input[type='password'] {
  position: relative;
  box-sizing: border-box;
  padding: 16px;
  height: 48px;
  width: 100%;
  border: 1px solid var(--line);
  border-radius: 6px;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.input-wrap button {
  border-radius: 6px;
  height: 48px;
}

.input {
  width: 100%;
}

.input.error input {
  border: 1px solid var(--error);
  margin-bottom: 10px;
}

.time-input .input.error input {
  margin-bottom: 0;
}

.textarea-custom {
  resize: none;
  height: 96px;
  line-height: 20px;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url('../components/atoms/Icon/svg/arrow_below_fill.svg') no-repeat calc(100% - 10px) center;
}

select:focus,
input[type='text']:focus,
input[type='number']:focus,
input[type='tel']:focus,
input[type='password']:focus,
select:active,
input[type='text']:active,
input[type='number']:active,
input[type='tel']:active,
input[type='password']:active {
  border: 1px solid var(--grey-1);
  outline: none;
}

.input.error input:focus {
  border: 1px solid var(--error);
}

select::placeholder,
input::placeholder,
textarea::placeholder {
  color: var(--placeholder)
}

input[type='checkbox'] {
  display: none;
}

input.input-small,
select.select-small {
  width: 80px;
  padding: 16px 18px;
  background-color: var(--white);
}

input.input-small {
  text-align: center;
}

/** radio */
input[type="radio"] {
  display: none;
}

.radio-set {
  display: flex;
  gap: 6px;
  align-items: center;
}


.radio-set .selected {
  color: var(--blue)
}


label[for^="radio-"] {
  outline: none;
  -webkit-tap-highlight-color: transparent;
}



@media (max-width: 1000px) {

  input.input-small,
  select.select-small {
    width: 68px;
  }

  .radio-set {
    gap: 4px;
  }

  .radio-set span {
    font-size: 13px;
  }
}

.label-small {
  font-size: 11px;
}

table .label-small {
  margin-top: 6px;
}

.order-num-label {
  display: block;
  background-color: var(--light-grey-2);
  border-radius: 6px;
  width: fit-content;
  font-size: var(--txt-size-small);
  color: var(--light-blue);
  padding: 6px 10px;
  text-align: center;
  margin-top: 6px;
}

.order-num-label.using {
  background-color: var(--green);
  color: var(--white);
}

.order-num-label.pending {
  color: var(--green);
}

span[class^="bg-"],
button[class^="bg-"],
div[class^="bg-"],
.status {
  display: inline-block;
  border-radius: 6px;
  line-height: 1 !important;
  min-width: 62px;
  width: fit-content;
  padding: 6px 10px;
  text-align: center;
  font-size: var(--txt-size-small);
}

span[class^="bg-"].fit-content,
button[class^="bg-"].fit-content,
div[class^="bg-"].fit-content,
.status.fit-content {
  min-width: auto;
}

.bg-grey {
  background-color: var(--light-grey-2);
  color: var(--light-blue);
}

.bg-grey-3 {
  background-color: var(--grey-3);
  color: var(--white);
}


.bg-blue {
  background-color: var(--light-blue);
  color: var(--white);
}

.bg-red {
  background-color: var(--pink);
  color: var(--red);
}

.bg-green {
  background-color: #b8e9ca;
  color: #0a8b33;
}

.status.completed {
  /* background-color: var(--light-grey-2); */
  color: var(--blue);
}

.status.pending {
  /* background-color: var(--light-grey-2); */
  color: var(--green);
}

.status.canceled {
  /* background-color: var(--light-grey-2); */
  color: var(--red);
}

.label-wrap {
  display: flex;
  justify-content: center;
}

.checkbox-wrap {
  display: flex;
  align-items: center;
  gap: 8px;
}


table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  padding: 0;
  margin: 0;
}


.box-light-grey-1 {
  display: block;
  background-color: var(--light-grey-1);
}


thead {
  background-color: var(--light-grey-2);
}


th,
td {
  text-align: center;
}

th {
  padding: 9px 10px;
}

td {
  padding: 16px;
  border-bottom: 1px solid var(--line);
}

tbody {
  background-color: var(--white);
}

th:first-child,
td:first-child {
  border-left: none;
}

td.large {
  min-height: 100px;
}

td.td-grey {
  background-color: var(--light-grey-1);
  padding: 9px 16px;
}

td .item-set {
  display: block;
  margin-bottom: 6px;
}

td .item-set:last-child {
  margin-bottom: 0;
}

.table-title {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 14px;
}

.table-summary-button {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: right;
}

.table-summary-button::after {
  content: '';
  display: inline-block;
  width: 16px;
  height: 16px;
  background: url('../components/atoms/Icon/svg/table_see_more.svg') 50% no-repeat;
}

@media (max-width: 1000px) {
  .table-title {
    position: relative;
    flex-direction: column;
    align-items: flex-start;
    gap: 14px;
  }

  .table-title .download {
    position: absolute;
    top: 0;
    right: 0;
    padding: 6px 8px;
  }

  td {
    padding: 16px 2px;
  }
}

.w-full {
  width: 100%;
}

/*padding*/
.p-4 {
  padding: 20px;
}

/* margin */
.margin-0 {
  margin: 0 !important;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-28 {
  margin-bottom: 28px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-8 {
  margin-bottom: 8px;
}

.mb-12 {
  margin-bottom: 12px;
}

.mt-30 {
  margin-top: 30px;
}

.pt-10 {
  padding-top: 10px;
}

.pt-20 {
  padding-top: 20px;
}

.w-22 {
  width: 22px;
}

.flex {
  display: flex;
}

.align-center {
  align-items: center;
}

.gap-6 {
  gap: 6px;
}


.mobile {
  display: none !important;
}

.justify-between {
  justify-content: space-between;
}

@media (max-width: 1000px) {
  .pc {
    display: none !important;
  }

  .mobile {
    display: block !important;
  }
}

.time-input {
  display: flex;
  gap: 8px;
  align-items: center;
}

.time-input+span.error {
  margin-top: 10px;
  display: block;
}

.time-input .error input,
.time-input select.error,
.time-input .error span {
  border: 1px solid var(--error);
  color: var(--black)
}


.reservation-item-list input[type='text'],
.reservation-item-list input[type='number'],
.reservation-item-list input[type='tel'] {
  padding: 12px 16px;
}

.time-input .input {
  width: auto;
}

.daytime-input-set {
  display: flex;
  gap: 8px;
}

.daytime-input-set input {
  display: none;
}

.daytime-input-button {
  display: block;
  border: 1px solid var(--line);
  padding: 16px 14px;
  height: 48px;
  border-radius: 6px;
  background-color: var(--white);
}


@media (max-width: 1000px) {
  .daytime-input-button {
    padding: 16px 12px;
  }

  .reservation-item-list input[type='text'],
  .reservation-item-list input[type='number'],
  .reservation-item-list input[type='tel'] {
    padding: 14px 16px;
    font-size: var(--txt-size-regular);
    line-height: 1;
  }
}


.daytime-input-button.selected {
  border: 1px solid var(--blue);
  background-color: var(--blue);
  color: var(--light-grey-2);
}

/* pagination */
.pagination-wrap {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.pagination {
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 12px;
}

.pagination .control {
  width: 20px;
  height: 20px;
}

.pagination-numbers {
  display: flex;
  align-items: center;
  gap: 8px;
}

.page-number {
  background: transparent;
  padding: 0;
  width: 24px;
  height: 24px;
}


.page-number.selected {
  background-color: var(--grey-1);
  border-radius: 6px;
  color: var(--light-grey-2);
}

.popup-flex {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.tag-input {
  outline: none !important;
  border: none !important;
}


/**************************** 견적서 로딩 ****************************/
.loader {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin: 3em;
  display: inline-block;
  position: relative;
  vertical-align: middle;
}

.loader,
.loader:before,
.loader:after {
  animation: 1s infinite ease-in-out;
}

.loader:before,
.loader:after {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
}

.loader-9,
.loader-10 {
  background-color: white;
  animation: loader9 0.4s infinite linear;
}

.loader-10 {
  animation: loader10 60s infinite ease-in-out;
}

.loader-9:before,
.loader-10:before {
  content: '';
  width: 80%;
  height: 80%;
  background-color: white;
  top: 10%;
  left: 10%;
  box-shadow: 5px -3px 0 rgba(255, 100, 100, 0.7), 5px 5px 0 rgba(100, 255, 100, 0.7), -3px 5px 0 rgba(100, 100, 255, 0.7), -5px -5px 0 rgba(240, 240, 120, 0.7);
}

.loader-9:after,
.loader-10:after {
  content: '';
  border: 3px solid white;
  z-index: 2;
  top: -3px;
  left: -3px;
}

@keyframes loader9 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes loader10 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360000deg);
  }
}

/****************** sweetalert *************************/

.swal2-popup {
  border-radius: 15px;
}
.swal2-title {
  padding: .8em 1em!important;
}
.swal2-title .title {
  width:100%;
  display: flex !important; 
  flex-direction: row !important;
  justify-content: space-between; 
}

.swal2-title .title .title-text {
  font-size: 20px !important;
}
.swal2-title .title .title-btn {
  padding: 3px 0px;
}
.swal2-title .title .title-btn button {
  justify-items: center;
  width:14px !important;
  height:14px !important;
}
.swal2-title .title .title-btn button {
  width:14px !important;
  height:14px !important;
  padding : 0 !important;
}
.swal2-title .title .title-btn button img {
  width:14px !important;
  height:14px !important;
}

.swal2-html-container {
  z-index: 1;
  justify-content: center;
  margin: 0 !important;
  padding: .5em 1.6em !important;
  overflow: auto;
  color: inherit;
  font-size: 16px;
  font-weight: normal;
  line-height: normal;
  text-align: center;
  word-wrap: break-word;
  word-break: break-word;
  max-height: 450px;

}
.swal2-html-container .desc {
  font-size: 16px;
  text-align: left;
  /* font-weight: bold; */
}
.swal2-actions {
  width:100%;
  display: flex !important; 
  flex-direction: row !important;
  padding: .5em 1.45em .1em !important;
  margin : 0 !important;
}

.swal2-actions button {
  width:45%;
  height:50px;
  border-radius: 10px !important;
}

.swal2-cancel {
  width: 100% !important;
  color: #8B8D98 !important;
  font-weight: bold;
  font-size: 16px !important;
}

.swal2-confirm {
  width: 100% !important;
  color: #fff !important;
  font-weight: bold;
  font-size: 16px !important;
}

/* 버튼쪽 외곽선 없애기 */
.swal2-default-outline:focus {
  box-shadow:none !important;
 }


.swal2-html-container .list ul li {
  width:100%;
  background-color: #fafafb;
  margin:10px 0;
  border-radius: 10px;

}

.swal2-html-container .list ul li .node {
  display:flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
}

.swal2-html-container .list ul li .node .sub-node {
  text-align: left;
  display:flex;
  flex-direction: column;
  gap:5px;

}

.swal2-html-container .list ul li .node .select-btn {
  background-color: #e7f4f7;
  color: #1eafd2;
}


/************************************************************************************/


.swal2-html-container .title-reportdetail {
  text-align: left;
  font-size: 18px;
  font-weight: bold;
}

.swal2-html-container .content {
  padding: 20px 0px;
}

.swal2-html-container .content .content-sub-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
}

.swal2-html-container .content .content-sub-container .component {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.swal2-html-container .content .content-sub-container .component .indent {
  padding-left: 10px;
}

.swal2-html-container .content .content-sub-container .component.bold div span {
  font-weight: bold;
  font-size: 16px !important;
}